.crag-info-status {
    text-align: center;
}
.crag-info-status-good {
    background-color: green;
    color: white;
}
.crag-info-status-mixed {
    text-align: center;
    background-color: orange;
    color: white;
}
.crag-info-status-bad {
    background-color: red;
    color: white;
}
.crag-info-name {
}
.crag-info-favicon {
    float: right;
    color: red;
    width: 48px;
    height: 48px;
    padding: 8px;
}
.crag-info-favicon svg {
    width: 100%;
    height: 100%;
}
.crag-location {
    text-align: center !important;
}
.crag-location-text {
    text-align: center;
    margin-bottom: 1rem;
}
.crag-climb-types {
    text-align: center;
}
.crag-climb-types img {
    margin-left: 5px;
    margin-right: 5px;
}
