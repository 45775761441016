.crags {
    padding-bottom: 0.75rem;
}

.crag-item + .crag-item {
    border-top: 1px solid black;
}

.crag-item {
    padding: 0.75rem;
    /*border-bottom: 1px solid black;*/
}

.crag-item-thumbnail {
    padding-right: 0.75rem;
    float: left !important;
}

.crag-item-thumbnail img {
    border-radius: 50% !important;
}

.crag-item-name {
    font-weight: 700 !important;
}

.crag-item-location {
    font-style: italic !important;
}

.nav-tabs {
    margin-bottom: 1rem;
}

.nav-tabs .nav-link {
    cursor: pointer;
}